<template>
<div>
  <div v-if="membershipInfo.organizationInfo">
    <v-card
      class="mx-auto"
      max-width="400"
    >
      <v-img
        class="white--text align-end"
        height="200px"
        :src="membershipInfo.definition[membershipInfo.organizationInfo.membership_level_code].card_img_url"
      >
        <v-card-title>
          {{ membershipInfo.definition[membershipInfo.organizationInfo.membership_level_code].category }} (级别{{membershipInfo.organizationInfo.membership_level_code}})
          <v-chip
            class="ma-2 caption"
            color="primary"
            text-color="white"
            v-if="isTempMembershipLevel"
            small
          >
            临时提升
          </v-chip>
        </v-card-title>
        <v-card-subtitle class="white--text"> {{ membershipInfo.organizationInfo.name }} </v-card-subtitle>
      </v-img>

      <v-card-subtitle class="pb-0">会员特权</v-card-subtitle>
      <v-card-text class="text--primary">
        <div>优惠费率: {{membershipInfo.organizationInfo.totalFeePercentage}}% <span class="caption grey--text">(不含场地费)</span></div>
        <div v-if="membershipInfo.organizationInfo.membership_level_code < 11 && membershipInfo.organizationInfo.membership_level_code > 0">预估收入: <span class="caption grey--text">约</span> {{membershipInfo.organizationInfo.incomePerStudentPer90MinFen / 100}} <span class="caption grey--text">元/人/90分钟 (仅新招学员)</span> </div>
      </v-card-text>
      <v-divider class="mx-4"></v-divider>
      <v-card-subtitle class="pb-0">授课活跃指数</v-card-subtitle>
      <v-card-text>
        <div class="my-3">
          <div class="red lighten-4">
            <v-progress-linear
              v-if="membershipInfo.organizationInfo.membership_level_code < 11 && membershipInfo.organizationInfo.membership_level_code > 0"
              v-model="longPeriodAvgStudentMinPerWkPercent"
              :buffer-value="instantAvgStudentMinPerWkPercent"
              color="primary"
              background-color="pink lighten-3"
            ></v-progress-linear>
          </div>
          <div class="d-flex">
            <span class="caption">当前(长/短期): {{membershipInfo.organizationInfo.long_period_avg_student_min_per_wk}}/{{membershipInfo.organizationInfo.instant_avg_student_min_per_wk}} </span>
            <v-spacer></v-spacer>
            <span v-if="membershipInfo.organizationInfo.membership_level_code < 11 && membershipInfo.organizationInfo.membership_level_code > 0" class="caption">升级({{membershipInfo.organizationInfo.membership_level_code + 1}}-{{membershipInfo.definition[membershipInfo.organizationInfo.membership_level_code + 1].category}}): {{membershipInfo.config.MEMBERSHIP_QUANLIFICATION[membershipInfo.organizationInfo.membership_level_code + 1]}}</span>
          </div>
        </div>
        <div class="caption text--secondary">活跃指数根据在读学员数、授课频度、时长等综合计算。</div>
      </v-card-text>
    </v-card>

    <v-alert class="ma-5" type="success" v-if="membershipInfo.organizationInfo.membership_level_code === 0">
      <strong>进一步提高收入</strong>
      <p>您的机构现处于实习状态, 可以主动联系您的片区负责人完成认证。转正后即可享有动态服务费率权益(随出课频率升高而动态降低服务费率)。详情请参见: 秋邀会员体系 -> 会员等级表。</p>
    </v-alert>

    <v-alert class="ma-5" type="success" v-if="isTempMembershipLevel">
      <strong>临时提升。</strong>
      <p>已按最新教学活跃度临时调整机构会员等级至{{membershipInfo.organizationInfo.membership_level_code}}级(当前级别为:{{membershipInfo.organizationInfo.membership_level_code_stable}}级)，如继续保持此活跃度将实现升级。如保持此活跃指数，新结课程将临时按新优惠服务费率结算</p>
    </v-alert>

    <v-alert class="ma-5" type="warning" v-if="membershipDowngradeRisk">
      <strong>降级风险。</strong>
      <p>机构长期平均授课活跃指数({{membershipInfo.organizationInfo.long_period_avg_student_min_per_wk}})低于当前级别({{membershipInfo.organizationInfo.membership_level_code_stable}}-{{membershipInfo.definition[membershipInfo.organizationInfo.membership_level_code_stable].category}})要求({{membershipInfo.config.MEMBERSHIP_QUANLIFICATION[membershipInfo.organizationInfo.membership_level_code_stable]}})。如不提升授课活跃度，机构评级将于 <strong> {{timeToDowngrade}} </strong> ({{ downgradeTime.format('YYYY-MM-DD HH:mm:ss') }}) 由系统自动下调至 {{membershipInfo.definition[membershipInfo.organizationInfo.longPeriodMembershipLevel].category}} (级别{{membershipInfo.organizationInfo.longPeriodMembershipLevel}})。</p>
    </v-alert>

    <v-divider class="ma-5"></v-divider>

    <!-- 会员制度 -->
    <div class="ma-5">
      <div class="title text-center my-5">
        秋邀会员体系
      </div>
      <div>
        <p class="text--secondary">秋邀机构会员等级由机构的授课活跃指数决定：当机构的授课活跃指数达到相应的等级要求即可升级，不同等级的会员在秋邀平台享有不同类型的会员专属权益。</p>
      </div>
    </div>
    <v-tabs
      v-model="tab"
      centered
      icons-and-text
    >
      <v-tabs-slider></v-tabs-slider>

      <v-tab href="#tab-1">
        活跃指数计算
        <v-icon>timelapse</v-icon>
      </v-tab>

      <v-tab href="#tab-2">
        升降级规则
        <v-icon>trending_up</v-icon>
      </v-tab>

      <v-tab href="#tab-3">
        会员等级表
        <v-icon>card_membership</v-icon>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item
        key=1
        value="tab-1"
      >
        <v-card flat>
          <v-card-text>
            <p>活跃指数是机构在一周内授课总人数乘以授课时长之和。</p>
            <h2 class="body-1">计算实例</h2>
            <p>例如，某机构共开设 2 个班，每班 5 名学员，授课频率均为 2次课/周，每次授课90分钟。</p>
            <p>活跃指数计算如下:</p>
            <p>活跃指数 = 2班 * 5人/班 * 90分/课 * 2课/周 = 1800 [人X分钟/周]</p>
            <h2 class="body-1">长/短期活跃指数</h2>
            <p>为了更为精确地反映机构教学活跃指数，秋邀分别在两个时间段实时对机构授课活跃指数进行计算：
              长期({{ membershipInfo.config.MEMBERSHIP_EVALUATION_LONG_PERIOD_DAY }}天)平均授课活跃指数；
              短期({{ membershipInfo.config.MEMBERSHIP_EVALUATION_SHORT_PERIOD_DAY }}天)授课活跃指数。
              计算均只考虑已完成结课的活动。
            </p>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item
        key=2
        value="tab-2"
      >
        <v-card flat>
          <v-card-text>
            <h2 class="body-1">升级</h2>
            <p>如果机构长期授课活跃指数满足升级条件，则机构会员级别提升至对应等级。</p>
            <h2 class="body-1">临时升级</h2>
            <p>如果机构的授课活跃指数在最近{{ membershipInfo.config.MEMBERSHIP_EVALUATION_SHORT_PERIOD_DAY }}天内暂时达到升级要求，则临时对级别进行调整，机构临时享受对应权益(如不间断保持该活跃指数{{ membershipInfo.config.MEMBERSHIP_EVALUATION_LONG_PERIOD_DAY }}天，则可升级)。</p>
            <h2 class="body-1">降级</h2>
            <p>如果机构长期授课活跃指数已不满足于所处级别要求达{{membershipInfo.config.MEMBERSHIP_HYSTERESIS_DAY}}天, 则进行降级。</p>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item
        key=3
        value="tab-3"
      >
        <v-card flat class="ma-10">
          <v-simple-table style="min-width: 100px;">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">级</th>
                  <th class="text-left">名称</th>
                  <th class="text-left">总服务费率</th>
                  <th class="text-left">达标指数[人X分钟/周]</th>
                  <th class="text-left">新招学员预估收入</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in membershipTable" :key="item.category">
                  <td class="caption">{{ item.code }}</td>
                  <td class="caption">{{ item.category }}</td>
                  <td class="caption">{{ item.totalFeePercentage }} %</td>
                  <td class="caption">{{ item.teaching_intensity }}</td>
                  <td class="caption">{{ item.incomePerStudentPer90MinYuan? item.incomePerStudentPer90MinYuan + ' [元/人/90分钟]' : '自由定价'}}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
        <v-alert class="ma-5" type="success" v-if="isTempMembershipLevel">
          <p>提示：在等级发生变动后，可期待收入将对新报名学员生效。</p>
        </v-alert>
      </v-tab-item>
    </v-tabs-items>
  </div>
  <!-- display overlay on the whole page while loading -->
  <v-overlay :value="$store.state.queryingState">
    <v-progress-circular
      indeterminate
      color=""
    ></v-progress-circular>
  </v-overlay>
</div>
</template>

<script>
import OrganizationService from '@/services/OrganizationService'
import moment from 'moment'
// import util from '@/util'

export default {
  components: {
  },
  computed: {
    // 判定是否存在降级风险
    membershipDowngradeRisk: function () {
      return (
        (this.membershipInfo.organizationInfo.longPeriodMembershipLevel < this.membershipInfo.organizationInfo.membership_level_code_stable) && this.membershipInfo.organizationInfo.membership_level_code !== 12 && this.membershipInfo.organizationInfo.membership_level_code !== 0
      )
    },
    // 计算会员降级具体日期
    downgradeTime: function () {
      let lastQuanlificationTime = moment(this.membershipInfo.organizationInfo.membership_level_last_quanlification_time)
      let downgradeTime = lastQuanlificationTime.add(this.membershipInfo.config.MEMBERSHIP_HYSTERESIS_DAY, 'days')
      return downgradeTime
    },
    // 计算现在距离会员降级所需时间
    timeToDowngrade: function () {
      let now = moment()
      return now.to(this.downgradeTime)
    },
    // 判定是否为临时升级
    isTempMembershipLevel: function () {
      return this.membershipInfo.organizationInfo.membership_level_code > this.membershipInfo.organizationInfo.membership_level_code_stable && this.membershipInfo.organizationInfo.membership_level_code !== 12 && this.membershipInfo.organizationInfo.membership_level_code !== 0
    }
  },
  data () {
    return {
      organizationId: null,
      membershipInfo: {},
      instantAvgStudentMinPerWkPercent: 0,
      longPeriodAvaStudentMinPerWkPercent: 0,
      tab: null,
      text: 'text',
      membershipTable: []
    }
  },

  async mounted () {
    // set queryingState
    this.$store.dispatch('setQueryingState', true)

    if (this.$route.query.organizationId) {
      this.organizationId = this.$route.query.organizationId
      console.log('path query detected: ')
      console.log(this.$route.query.organizationId)
    } else {
      this.organizationId = this.$store.state.account.organizations[0].id
    }

    // get organization and membership info
    let res = await OrganizationService.getMembershipInfo({ organizationId: this.organizationId })
    this.membershipInfo = res.data

    // preparition for progress bar
    let membershipLevel = this.membershipInfo.organizationInfo.membership_level_code
    let instantAvgStudentMinPerWk = this.membershipInfo.organizationInfo.instant_avg_student_min_per_wk
    let longPeriodAvgStudentMinPerWk = this.membershipInfo.organizationInfo.long_period_avg_student_min_per_wk
    let currentLevelQuanlification, nextLevelQuanlification
    if (membershipLevel < 12 && membershipLevel > 0) {
      currentLevelQuanlification = this.membershipInfo.config.MEMBERSHIP_QUANLIFICATION[membershipLevel]
      nextLevelQuanlification = this.membershipInfo.config.MEMBERSHIP_QUANLIFICATION[membershipLevel + 1]
    }
    this.instantAvgStudentMinPerWkPercent = Math.round(100 * (instantAvgStudentMinPerWk - currentLevelQuanlification) / (nextLevelQuanlification - currentLevelQuanlification))
    this.longPeriodAvgStudentMinPerWkPercent = Math.round(100 * (longPeriodAvgStudentMinPerWk - currentLevelQuanlification) / (nextLevelQuanlification - currentLevelQuanlification))

    // 构建会员等级表
    this.membershipInfo.definition.forEach((element, index) => {
      if (element.code !== 12) {
        this.membershipTable.push({
          code: element.code,
          category: element.category,
          totalFeePercentage: element.totalFeePercentage,
          teaching_intensity: this.membershipInfo.config.MEMBERSHIP_QUANLIFICATION[index],
          incomePerStudentPer90MinYuan: element.incomePerStudentPer90MinFen / 100
        })
      }
    })

    // 更新storage.state.account.organizations
    // let accountUpdated = this.$store.state.account
    // let organizationInfoUpdated = accountUpdated.organizations[0]
    // organizationInfoUpdated = { ...organizationInfoUpdated, ...this.membershipInfo.organizationInfo }
    // accountUpdated.organizations[0] = organizationInfoUpdated
    // this.$store.dispatch('setAccount', accountUpdated)

    // set queryingState
    this.$store.dispatch('setQueryingState', false)
  },

  methods: {
    // async getLessonTemplatesByTutorId (tutorId) {
    //   // get template list by tutor Id
    //   const res = await LessonService.getLessonTemplatesByTutorId(tutorId)
    //   console.log('lessonTemplates:', res.data.lessonTemplates)
    //   return res.data.lessonTemplates
    // }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
