import Api from '@/services/Api' // axois object created in api.js

export default { // export its register method
  getMembershipInfo ({ organizationId }) {
    console.log('[OrganizationService getMembershipInfo] Sending request via GET method to server, waiting for server response ...')
    return Api().get('organization/membership-info', { params: { organization_id: organizationId } })
  },
  getOrganizationInfo ({ organizationId }) {
    console.log('[OrganizationService getOrganizationInfo] Sending request via GET method to server, waiting for server response ...')
    return Api().get('organization/organization-info', { params: { organizationId: organizationId } })
  },
  getOrganizationsInfo ({ organizationIds, managerIds, trainerIds }) {
    console.log('[OrganizationService getOrganizationsInfo] Sending request via GET method to server, waiting for server response ...')
    return Api().get('organization/organizations-info', { params: { organizationIds, managerIds, trainerIds } })
  }
  // studentClassEnroll (studentIds, classId) {
  //   console.log('#\nstudentClassEnroll: sending request via POST method to server, waiting for response ...')
  //   return Api().post('program/class-enrollment', { studentIds: studentIds, classId: classId })
  // },
  // upsertClass (classInfo) {
  //   console.log('ProgramService.upsertClass starts ... ')
  //   return Api().put('program/class-upsert', classInfo)
  // },
  // deleteClass (classId) {
  //   return Api().delete('program/class-delete', { params: { classId: classId } })
  // },
  // getTutors ({ tutorId, mobile, organizationId }) {
  //   console.log('Sending request via GET method to server, waiting for server response ...')
  //   return Api().get('program/tutors', { params: { tutor_id: tutorId, mobile, organization_id: organizationId } })
  // }
}
